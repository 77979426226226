document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  bootstarp_validations();
})

$.extend( $.fn.datepicker.defaults, {
  language: "ru",
  format: 'dd.mm.yyyy',
  autoclose: true,
  todayHighlight: true
});

function bootstarp_validations(){
  var forms = document.getElementsByClassName('needs-validation');
  var validation = Array.prototype.filter.call(forms, function(form) {
    form.addEventListener('submit', function(event) {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add('was-validated');
    }, false);
  });
}
