$.extend( true, $.fn.dataTable.defaults, {
  responsive: true,
  processing: true,
  autoWidth: false,
  stateSave: true,
  pagingType: "dont_show_one",
  dom: 'r<"row"<"col"l><"col"f>><"row"t><"row"<"col"i><"col"p>>',
  language: {
    "processing": "<label class='mt-3 h3'><i class='fa fa-cog fa-spin'></i> Загрузка данных...</label>",
    "search": "<i class='fa fa-search' title='Поиск'></i>",
    "lengthMenu": "<i class='fa fa-bars' title='Кол-во записей на странице'></i> _MENU_ ",
    "info": "<small class='text-muted'>Записи с _START_ до _END_ из _TOTAL_ записей</small>",
    "infoEmpty": "",
    "infoFiltered": "",
    "infoPostFix": "",
    "loadingRecords": "Загрузка записей...",
    "zeroRecords": "<label class='subheader'>нет данных</small>",
    "emptyTable": "В таблице отсутствуют данные",
    "paginate": {
      "first": "Первая",
      "previous": "<i class='fa fa-angle-double-left'></i>",
      "next": "<i class='fa fa-angle-double-right'></i>",
      "last": "Последняя"
    },
    "aria": {
      "sortAscending": ": активировать для сортировки столбца по возрастанию",
      "sortDescending": ": активировать для сортировки столбца по убыванию"
    }
  },
});

$.fn.DataTable.ext.pager.dont_show_one = function(page, pages){
  var numbers = [];
  var buttons = $.fn.DataTable.ext.pager.numbers_length;
  var half = Math.floor( buttons / 2 );
  var _range = function ( len, start ){
    var end;
    if ( typeof start === "undefined" ){
      start = 0;
      end = len;
    } else {
      end = start;
      start = len;
    }
    var out = [];
    for ( var i = start ; i < end; i++ ){ out.push(i); }
    return out;
  };
  if (pages <= buttons) {
    numbers = _range(0, pages);
  } else if(page <= half) {
    numbers = _range(0, buttons - 2);
    numbers.push("ellipsis");
    numbers.push(pages - 1);
  } else if(page >= pages - 1 - half) {
    numbers = _range(pages - (buttons - 2), pages);
    numbers.splice(0, 0, "ellipsis");
    numbers.splice(0, 0, 0);
  } else {
    numbers = _range(page - 1, page + 2);
    numbers.push("ellipsis");
    numbers.push(pages - 1);
    numbers.splice(0, 0, "ellipsis");
    numbers.splice(0, 0, 0);
  }
  numbers.DT_el = 'span';
  return pages <= 1 ? '' : [ 'previous', numbers, 'next' ];
}

$(document).on('preInit.dt', function(e, settings) {
  var api, table_id, url;
  api = new $.fn.dataTable.Api(settings);
  table_id = "#" + api.table().node().id;
  url = $(table_id).data('source');
  if (url) {
    return api.ajax.url(url);
  }
});


// init on turbolinks load
$(document).on('turbolinks:load', function() {
  if (!$.fn.DataTable.isDataTable("table[id^=dttb-]")) {
    $("table[id^=dttb-]").DataTable();
  }
});

// turbolinks cache fix
$(document).on('turbolinks:before-cache', function() {
  var dataTable = $($.fn.dataTable.tables(true)).DataTable();
  if (dataTable !== null) {
    dataTable.clear();
    dataTable.destroy();
    return dataTable = null;
  }
});
